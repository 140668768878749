<template>
	<div class="row flex flex-wrap" :style="{'--gutter-x': gutterX}">
		<slot/>
	</div>
</template>

<script>
export default {
	name: "TRow",
	props: {
		gutterX: {
			default: '1.5rem',
			type: String,
		}
	}
}
</script>

<style scoped>
.row {
	--gutter-x: 1.5rem;
	--gutter-y: 0;
	margin-top: calc(-1 * var(--gutter-y));
	margin-right: calc(-.5 * var(--gutter-x));
	margin-left: calc(-.5 * var(--gutter-x));
}
</style>
