<template>
	<div class="container mx-auto" :style="{'--gutter-x': gutterX}">
		<slot/>
	</div>
</template>

<script>
export default {
	name: "TContainer",
	props: {
		gutterX: {
			default: '1.5rem',
			type: String,
		}
	}
}
</script>

<style scoped>
.container {
	--gutter-x: 1.5rem;
	--gutter-y: 0;
	padding-right: calc(.5 * var(--gutter-x));
	padding-left: calc(.5 * var(--gutter-x));
}
</style>
