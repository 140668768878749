<template>
	<img src="@/assets/logo-text.svg" alt="MacGyver" :style="style">
</template>

<script>
export default {
	name: "Logo",
	props: {
		width: String,
		height: String
	},
	computed: {
		style() {
			const style = {display: 'inline-block'}
			// if (this.width) style.width = this.width
			// else if (this.height) style.height = this.height
			// else style.height = '4rem'
			return style
		}
	}
}
</script>

<style scoped>

</style>
