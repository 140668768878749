<template>
	<header>
<!--		<iframe-->
<!--			src="https://player.vimeo.com/video/705177002?h=c08a4331b5&badge=0&autoplay=1&loop=1&autopause=0&muted=1&player_id=0&app_id=58479"-->
<!--			allow="autoplay; fullscreen; picture-in-picture" allowfullscreen-->
<!--			title="MaximumFX Showreel 2022"></iframe>-->
		<video src="@/assets/videos/macgyver_vfx-reel.mp4" autoplay muted loop playsinline/>
	</header>
	<div class="w-1/4 w-2/3 sm:w-1/2 md:w-1/4"></div>
	<main>
		<t-container id="post" class="text-center my-60">
			<h1 class="text-xl font-bold mb-24">Services</h1>
			<t-row class="justify-center">
				<t-col v-for="(item, i) of post" :key="i" width="1/2" md="1/3">
					<PostItem :name="item.name" :icon="item.icon"/>
				</t-col>
			</t-row>
		</t-container>
		<t-container id="about-us" class="my-24" gutter-x="4rem">
			<t-row class="justify-center">
				<t-col width="fit" class="shrink lg:!w-fit !mx-0 text-right relative mb-10 md:mb-0">
					<h1 class="display-1 font-medium uppercase mb-6 lg:mb-0 w-fit inline-block">The Creative<br>one stop<br>shop</h1>
<!--					<div class="bar"></div>-->
				</t-col>
				<t-col width="full" lg="1/2" class="!mx-0">
					<p class="whitespace-pre-line mb-4">{{ content.Text_1 }}</p>
					<p class="whitespace-pre-line">{{ content.Text_2 }}</p>
				</t-col>
			</t-row>
<!--			<t-row class="py-24 justify-center">-->
<!--				<t-col width="full" lg="2/3">-->
<!--				</t-col>-->
<!--			</t-row>-->
<!--			<t-row :class="['justify-center carousel', carouselOff ? 'carousel-off' : '']">-->
<!--				<div-->
<!--					v-for="(img, i) of images[carouselIndex]" :key="i"-->
<!--					class="w-32 h-12 m-4 md:w-48 md:h-16 md:m-6 lg:w-64 lg:h-24 lg:m-10">-->
<!--					<img :src="img.pathLong" alt="" class="w-full h-full object-contain">-->
<!--					&lt;!&ndash;h-8 m-4 md:h-12 md:m-6 lg:h-16 lg:m-10&ndash;&gt;-->
<!--				</div>-->
<!--			</t-row>-->
		</t-container>
		<vue3-marquee :clone="true" :duration="150" class="mt-40">
			<div
				v-for="(img, i) of marquee[0]" :key="i"
				class="w-fit h-10 m-4 md:h-14 md:m-6 lg:h-14 lg:m-10">
<!--		    w-32 h-12 m-4 md:w-48 md:h-16 md:m-6 lg:w-64 lg:h-24 lg:m-10-->
				<img :src="img" alt="" class="h-full">
			</div>
<!--			<img v-for="(img, i) of images" :key="i" :src="img.pathLong" alt="" class="marquee-image">-->
		</vue3-marquee>
		<vue3-marquee :clone="true" :duration="150" direction="reverse" class="mb-40">
			<div
				v-for="(img, i) of marquee[1]" :key="i"
				class="w-fit h-10 m-4 md:h-14 md:m-6 lg:h-14 lg:m-10">
				<img :src="img" alt="" class="h-full">
			</div>
		</vue3-marquee>
		<section id="contact" class="py-32 text-right bg-black relative">
			<t-container class="relative pointer-events-none z-20">
				<t-row class="justify-end">
					<t-col width="full" md="1/2" class="!mx-0 pointer-events-auto">
	<!--				<p class="text-lg sm:text-xl md:text-2xl font-bold uppercase pb-4 sm:pb-7 md:pb-10">{{ content.ContactDescription }}</p>-->
						<p class="font-bold text-lg sm:pb-2 md:pb-4">{{ content.ContactDescription }}</p>
						<p class="pb-4">{{ content.ContactPhone }}</p>
						<p>{{ content.ContactEmail }}</p>
					</t-col>
				</t-row>
			</t-container>
			<a href="https://goo.gl/maps/ViuS6KeYomm493mV7" target="_blank" class="absolute inset-0 z-10"></a>
		</section>
	</main>
</template>

<script>
import { defineComponent } from 'vue';
import TContainer from "@/components/core/TContainer.vue";
import TRow from "@/components/core/TRow.vue";
import TCol from "@/components/core/TCol.vue";
import PostItem from "@/components/PostItem.vue";

const shuffle = (array) => {
	let currentIndex = array.length,  randomIndex

	// While there remain elements to shuffle.
	while (currentIndex != 0) {

		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex--

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex], array[currentIndex]]
	}

	return array
}


export default defineComponent({
	name: 'HomeView',
	components: {PostItem, TCol, TRow, TContainer},
	data() {
		return {
			images: [],
			imagesUngrouped: [],
			carouselIndex: 0,
			carouselOff: false,

			post: [
				{name: 'Editing', icon: require('@/assets/images/icons/Editing.png')},
				{name: 'Compositing', icon: require('@/assets/images/icons/Compositing.png')},
				{name: '3D', icon: require('@/assets/images/icons/3D.png')},
				{name: 'Motion Graphics', icon: require('@/assets/images/icons/Motion-Graphics.png')},
				{name: 'Color Grading', icon: require('@/assets/images/icons/Color-Grading.png')},
				{name: 'Audio', icon: require('@/assets/images/icons/Audio.png')},
				// {name: 'Delivery', icon: require('@/assets/images/icons/Delivery.png')},
			],
			content: require('@/assets/baseContent.json'),
		}
	},

	async created() {
		try {
			const req = await fetch(
				process.env.NODE_ENV === 'production' ?
					'./assets/content.json' :
					'../assets/content.json'
			)
			this.content = await req.json()
		} catch (e) {
			console.error('Error while loading site content', e)
		}
		// try {
		// 	const req = await fetch(
		// 		'http://localhost:1337/api/home?populate=Showreel%2CLogos%2Cpost_items%2CSEO',
		// 		{
		// 			method: 'GET',
		// 			headers: {
		// 				'Authorization': 'Bearer ' + process.env.VUE_APP_API_TOKEN,
		// 			},
		// 		}
		// 	)
		// 	const json = await req.json()
		// 	this.content = json.data.attributes
		// 	console.log(json.data.attributes)
		// } catch (e) {
		// 	console.error(e)
		// }

		setInterval(() => {
			this.carouselOff = true
			setTimeout(() => {
				if (this.carouselIndex >= this.images.length - 1)
					this.carouselIndex = 0
				else this.carouselIndex++
			}, 500)
			setTimeout(() => {
				this.carouselOff = false
			}, 500 * 2)
		}, 4000)
	},

	mounted() {
		this.importAll(require.context('../assets/images/brands/', true))
	},
	methods: {
		importAll(r) {
			let a = []
			r.keys().forEach((key) => a.push(r(key)))

			if (process.env.NODE_ENV === 'production')
				a = this.content.Brands.map(b => '/img/brands/' + b)

			const chunkSize = 7

			shuffle(a)

			this.imagesUngrouped = a

			for (let i = 0; i < a.length; i += chunkSize) {
				this.images.push(a.slice(i, i + chunkSize))
			}
		},
	},
	computed: {
		marquee() {
			const middleIndex = Math.ceil(this.imagesUngrouped.length / 2)

			const firstHalf = [...this.imagesUngrouped].splice(0, middleIndex);
			const secondHalf = [...this.imagesUngrouped].splice(-middleIndex);
			return [firstHalf, secondHalf]
		}
	}
});
</script>

<style scoped>
header {
	height: 100vh;
	background-color: black;
	position: relative;
	overflow: hidden;
}
header iframe,
header video {
	pointer-events: none;
	user-input: none;

	width: 100vw;
	height: 56.25vw;
	min-height: 100vh;
	min-width: 177.77vh;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.bar {
	position: absolute;
	bottom: .3rem;
	right: 2rem;
	width: 8rem;
	height: .25rem;
	background-color: white;
}
.carousel {
	transition: opacity .5s;
	/*min-height: 16rem;*/
}
.carousel-off {
	opacity: 0;
}

#contact {
	background: center no-repeat url("@/assets/images/map.png");
	background-size: cover;
}
#contact p {
	letter-spacing: .25em;
}
</style>
