<template>
	<nav :class="['fixed w-full flex items-center drop-shadow-lg z-50', affix ? 'bg-brand' : '']">
		<div class="flex-1 px-6">
			<Logo height="5rem" class="h-16 sm:h-20"/>
		</div>
<!--		<div v-if="$route.name === 'home'" class="flex-auto px-6 text-right">-->
<!--			<a class="nav-link" href="#post">Services</a>-->
<!--			<a class="nav-link" href="#about-us">About</a>-->
<!--			<a class="nav-link" href="#contact">Contact</a>-->
<!--		</div>-->
		<div class="hidden sm:block sm:flex-auto px-6 text-right">
			<router-link class="nav-link" to="/#post">Services</router-link>
			<router-link class="nav-link" to="/#about-us">About</router-link>
			<router-link class="nav-link" to="/#contact">Contact</router-link>
		</div>
	</nav>
	<router-view/>
<!--	<footer class="px-6 bg-brand">-->
<!--		<t-row class="items-center">-->
<!--&lt;!&ndash;			<t-col width="full" md="1/3" class="text-center md:text-left">&ndash;&gt;-->
<!--&lt;!&ndash;				<router-link to="/disclaimer" class="nav-link">Disclaimer</router-link>&ndash;&gt;-->
<!--&lt;!&ndash;				<router-link to="/privacyverklaring" class="nav-link">Privacyverklaring</router-link>&ndash;&gt;-->
<!--&lt;!&ndash;			</t-col>&ndash;&gt;-->
<!--			<t-col width="full" md="1/2" class="">-->
<!--				<p>&copy; 2023 MacGyver Amsterdam</p>-->
<!--			</t-col>-->
<!--			<t-col width="full" md="1/2" class="text-center md:text-right p-6">-->
<!--				<Logo/>-->
<!--			</t-col>-->
<!--		</t-row>-->
<!--&lt;!&ndash;		<div class="flex-auto">&ndash;&gt;-->
<!--&lt;!&ndash;		</div>&ndash;&gt;-->
<!--&lt;!&ndash;		<div class="flex-auto text-right p-6">&ndash;&gt;-->
<!--&lt;!&ndash;			<Logo/>&ndash;&gt;-->
<!--&lt;!&ndash;		</div>&ndash;&gt;-->
<!--	</footer>-->
</template>

<script>
import Logo from "@/components/Logo";

export default {
	components: {Logo},
	data() {
		return {
			affix: false
		}
	},
	mounted() {
		window.addEventListener('scroll', () => {
			this.affix = window.scrollY > window.innerHeight * .25
		})
	}
}
</script>

<style lang="scss">
html, body {
	@apply bg-brand text-white;
}
:target::before {
	content: "";
	display: block;
	height: 9rem;
	margin: -9rem 0 0;
}
nav {
	height: 6rem;
	transition: background-color .35s;

	.nav-link {
		font-size: 1rem;
		padding: 0.5rem;
		font-weight: 500;
	}

	@media (min-width: 640px) {
		height: 8rem;

		.nav-link {
			//font-size: 1rem;
			font-size: 1.2rem;
		}
	}

}
.nav-link {
	padding: 2rem;
	transition: color .35s;
	/*opacity: 1;*/
	display: inline-block;

	&:hover, &.active {
		/*opacity: .8;*/
		color: black;
	}
}
</style>
