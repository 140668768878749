import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/disclaimer',
		name: 'disclaimer',
		component: () => import(/* webpackChunkName: "disclaimer" */ '../views/Disclaimer.vue')
	},
	{
		path: '/privacyverklaring',
		name: 'privacyverklaring',
		component: () => import(/* webpackChunkName: "privacyverklaring" */ '../views/Privacyverklaring.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash)
			return {
				el: to.hash,
				top: 128 * 1.5,
				behavior: to.path === from.path ? 'smooth' : 'auto',
			}
		if (savedPosition)
			return savedPosition
	}
})

export default router
