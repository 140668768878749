<template>
	<div :class="classes" :style="style">
		<slot/>
	</div>
</template>

<script>
export default {
	name: "TCol",
	props: {
		width: {
			type: String,
			default: 'full',
		},
		sm: String,
		md: String,
		lg: String,
		xl: String,
	},
	computed: {
		style() {
			if (this.width === 'full') return {flex: '1 0 100%'}
			return {}
		},
		classes() {
			const c = ['col']
			if (this.sm) c.push('sm:max-w-' + this.sm, 'sm:flex-' + this.sm)
			if (this.md) c.push('md:max-w-' + this.md, 'md:flex-' + this.md)
			if (this.lg) c.push('lg:max-w-' + this.lg, 'lg:flex-' + this.lg)
			if (this.xl) c.push('xl:max-w-' + this.xl, 'xl:flex-' + this.xl)
			c.push(`max-w-${this.width}`)
			return c
		}
	}
}
</script>

<style scoped>
.col {
	width: 100%;
	padding-right: calc(var(--gutter-x) * .5);
	padding-left: calc(var(--gutter-x) * .5);
	margin-top: var(--gutter-y);
	@apply mx-auto;
}
</style>
