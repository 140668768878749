<template>
	<div class="text-center mb-16">
		<img :src="icon" :alt="name" class="d-block w-12 h-12 mx-auto mb-8">
		<p class="font-bold text-sm uppercase">{{name}}</p>
	</div>
</template>

<script>
export default {
	name: "PostItem",
	props: {
		icon: String,
		name: String
	}
}
</script>

<style scoped>
p {
	opacity: 1;
}
</style>
